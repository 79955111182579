































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ADD_CONTACT } from '../store';
import DoctorCombobox from '@/modules/contact/components/DoctorCombobox.vue';
import { ConservatorshipDetails } from '../../conservatorship/types';
import { WriteContact } from '../types';
import { ApiResponse } from '@/components/types';
import { createContact } from '@/modules/conservatorshipToContact/model';
import { Contact } from '@/modules/contact/types';

const Conservatorship = namespace('conservatorshipToContact');

@Component({
  components: {
    DoctorCombobox
  }
})
export default class AddDoctorDialog extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Conservatorship.Action(ADD_CONTACT) addContact!: (params: WriteContact) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;
  disabled: boolean = false;

  value = createContact(this.conservatorship.id, ['doctor']);

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = createContact(this.conservatorship.id, ['doctor']);
      this.disabled = false;

      return;
    }

    setTimeout(() => {
      this.error = null;
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  @Watch('value.name')
  watchName(value: string) {
    if (value) return;

    this.value = createContact(this.conservatorship.id, ['doctor']);
  }

  sync(contact: Contact) {
    if (!contact) {
      this.value = createContact(this.conservatorship.id, ['doctor']);
    }

    this.value.contact_id = contact.id;
    this.value.name = contact.name;
    this.value.additional = contact.additional;
    this.value.street = contact.street;
    this.value.zip_code = contact.zipCode;
    this.value.city = contact.city;
    this.value.phone_number = contact.phoneNumber;
    this.disabled = true;
  }

  async save() {
    this.error = null;

    return this.addContact(this.value);
  }
}
